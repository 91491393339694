<script>
import axios from "axios";
import { authHeader } from "../../../helpers/auth-header";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import ConfirmationModal from "@/components/modals/confirmation-modal";
import MessageModal from "@/components/modals/message-modal";

export default {
	components: {
		Layout,
		PageHeader,
		ConfirmationModal,
		MessageModal,
	},
	data() {
		const id = this.$route.params.id;

		return {
			id: id,
			title: 'REQUEST DETAILS',
			items: [
				{
					text: "Parent request"
				},
				{
					text: 'Request details',
				},
			],
			data: {
				requestor_name: '',
				phone: '',
				email: '',
				requestor_type: '',
				postal: '',
				type_of_lesson: '',
				student_name: '',
				student_level: '',
				status: '',
				service_id: '',
				service_name: '',
				subject_name: '',
				subject_duration_name:'',
				subject_frequency_name:'',
				tutor_type:'',
				tutor_rate:'',
				other_tutor_type: '',
				preferred_budget: '',
				gender_preference: '',
				preferred_time: '',
				remarks: '',
				assigned_tutor_email: '',
				assigned_date: '',
				address: '',
				unit: '',
				telegram_channel_id: '',
				estimated_commencement_date: '',
			},
            subject_duration_text: '',
            subject_frequency_text: '',
            tutor_type_text: '',
			errors: {},
		};
	},
	async mounted() {
        const result = await axios.get(`${process.env.VUE_APP_APIURL}/assignment/${this.id}`, {
            headers: authHeader(),
        });

        if (result && result.data['code'] === 'success') {
            const data = result.data['data']['assignment'];

            for (const [key, item] of Object.entries(data)) {
                if (key in this.data) {
                    if (key !== 'items') {
                        this.data[key] = item;
                    }
                }
            }

            const item = data.items[0];
            if (item) {
                for (const [key, value] of Object.entries(item)) {
                    if (key in this.data) {
                        this.data[key] = value;
                    }
                }
            }
        }

		this.retrieveSubjectDurations().then((durations) => {
            const subject_duration = durations.find(duration => duration.id == this.data.subject_duration_name);
            if (subject_duration) {
                this.subject_duration_text = subject_duration.duration + ' minutes';
            } else {
                this.subject_duration_text = '-';
            }
		});

		this.retrieveSubjectFrequency().then((frequencies) => {
            const subject_frequency = frequencies.find(frequency => frequency.id == this.data.subject_frequency_name);
            if (subject_frequency) {
                this.subject_frequency_text = subject_frequency.frequency > 1 ? subject_frequency.frequency + ' lessons per week' : subject_frequency.frequency + ' lesson per week';
            } else {
                this.subject_frequency_text = '-';
            }
		});

		this.retrieveTutorTypes().then((types) => {
			const tutor_type = types.find(type => type.id == this.data.tutor_type);
            if (tutor_type) {
                this.tutor_type_text = tutor_type.name + ' ($' + tutor_type.rate + '/hr)';
            } else {
                this.tutor_type_text = '-';
            }
		});
	},
	computed: {
		formattedTutorInfo() {
            let tutorTypes = [];
            let tutorRates = [];

            try {
                tutorTypes = JSON.parse(this.data.tutor_type);
            } catch (error) {
                console.error("Failed to parse tutor_type:", error);
                return '-';
            }

			if (typeof this.data.tutor_rate === 'number') {
				tutorRates = this.data.tutor_rate;
			} else {
				try {
					tutorRates = JSON.parse(this.data.tutor_rate);
				} catch (error) {
					console.error("Failed to parse tutor_rate:", error);
					return '-';
				}
			}

            if (!Array.isArray(tutorTypes)) {
                return '-';
            }

            if (typeof tutorRates === 'number') {
				let rateDisplay = this.data.service_id == 5 ? `$${tutorRates}/session` : `$${tutorRates}/hr`;
				
				const formattedInfo = tutorTypes.map((type) => {
					return `${type.name} (${rateDisplay})`;
				});

				// const formattedInfo = tutorTypes.map((type) => {
				// 	return `${type.name} ($${tutorRates}/hr)`;
				// });
				return formattedInfo.join(', ');
			} else {
				// If tutorRates is an array, create a map and format accordingly
				const tutorRatesMap = tutorRates.reduce((acc, rate) => {
					acc[rate.id] = rate.rate;
					return acc;
				}, {});

				const formattedInfo = tutorTypes.map((type) => {
					console.log('typetype', tutorRatesMap)
					const rate = tutorRatesMap[type.id];

					let rateDisplay = rate;
					console.log('this.data', this.data)
					if (this.data.preferred_budget == 1) {
						rateDisplay = this.data.service_id == 5 
							? `$${rate}/session`
							: `$${rate}/hr`;
					}

					return `${type.name} (${rateDisplay})`;
				});

				// const formattedInfo = tutorTypes.map((type) => {
				// 	const rate = tutorRatesMap[type.id];
				// 	return `${type.name} ($${rate}/hr)`;
				// });
				return formattedInfo.join(', ');
			}
        },
	},
	methods: {
		capitalizeFirstLetter(string) {
			if (string == '-' || string === undefined || string === null || string === '') {
				return '-';
			}

			return string.charAt(0).toUpperCase() + string.slice(1);
		},

		formatString(text) {
			let formattedText = '';
			if (text) {
				formattedText = text
				.split("_")
				.map(word => word.charAt(0).toUpperCase() + word.slice(1))
				.join(" ");
			}
            
			return formattedText;
		},

		async retrieveSubjectDurations() {
            this.loading = true;
            let subject_durations = [];

            try {
                const item = await axios.get(`${process.env.VUE_APP_APIURL}/field_management/subject_duration`, {
                    headers: authHeader(),
                });

                const result = await item.data;
                if (result['code'] === 'success') {
                    subject_durations = result['data'].subject_durations;
                }
            } catch (e) {
                console.error(e);
            }

            this.loading = false;
            return subject_durations;
        },

		async retrieveSubjectFrequency() {
            this.loading = true;
            let subject_frequencies = [];

            try {
                const item = await axios.get(`${process.env.VUE_APP_APIURL}/field_management/subject_frequency`, {
                    headers: authHeader(),
                });

                const result = await item.data;
                if (result['code'] === 'success') {
                    subject_frequencies = result['data'].subject_frequencys;
                }
            } catch (e) {
                console.error(e);
            }

            this.loading = false;
            return subject_frequencies;
        },

		async retrieveTutorTypes() {
            this.loading = true;
            let tutor_types = [];

            try {
                const item = await axios.get(`${process.env.VUE_APP_APIURL}/field_management/tutor_type`, {
                    headers: authHeader(),
                });

                const result = await item.data;
                if (result['code'] === 'success') {
                    tutor_types = result['data'].tutor_types;
                }
            } catch (e) {
                console.error(e);
            }

            this.loading = false;
            return tutor_types;
        },

        async approve() {
            const requestData = {};
            requestData.status = 'approved';

            const updateCallback = async () => {
                const result = await axios.post(`${process.env.VUE_APP_APIURL}/assignment/${this.id}`, requestData, {
                    headers: authHeader(),
                });

                if (result) {
                    this.$refs.confirmationModal.closeModal();
                    if (result.data['code'] === 'success') {
                        this.$refs.messageModal.showModal('Record has been updated successfully');
                    } else if (result.data['code'] === 'empty_telegram') {
                        this.$refs.messageModal.showModal('Please assign a Telegram channel.');
                    } else {
                        this.$refs.messageModal.showModal('Something went wrong. Please try again later.');
                    }
                }   
			};
			this.$refs.confirmationModal.showModal('Approve this request?', updateCallback);
        },

        async decline() {
            const requestData = {};
            requestData.status = 'rejected';
            
            const updateCallback = async () => {
                const result = await axios.post(`${process.env.VUE_APP_APIURL}/assignment/${this.id}`, requestData, {
                    headers: authHeader(),
                });

                if (result) {
                    this.$refs.confirmationModal.closeModal();
                    if (result.data['code'] === 'success') {
                        this.$refs.messageModal.showModal('Record has been updated successfully');
                    } else {
                        this.$refs.messageModal.showModal('Something went wrong. Please try again later.');
                    }
                }   
			};
			this.$refs.confirmationModal.showModal('Decline this request?', updateCallback);
        },

		assignChannel(telegram_channel_id, channel_name) {
			const requestData = {};
            requestData.telegram_channel_id = telegram_channel_id;
            
            const updateCallback = async () => {
                const result = await axios.post(`${process.env.VUE_APP_APIURL}/update_telegram/${this.id}`, requestData, {
                    headers: authHeader(),
                });

                if (result) {
                    this.$refs.confirmationModal.closeModal();
                    if (result.data['code'] === 'success') {
                        this.$refs.messageModal.showModal('Record has been updated successfully');
                    } else {
                        this.$refs.messageModal.showModal('Something went wrong. Please try again later.');
                    }
                }   
			};
			this.$refs.confirmationModal.showModal(`Confirm to tag to ${channel_name}?`, updateCallback);
		}
	}
};
</script>

<template>
	<Layout>
		<PageHeader :title="title" :items="items" />
		<ConfirmationModal ref="confirmationModal" />
		<MessageModal ref="messageModal" />

		<div>
			<div class="card">
				<div class="card-body">
					<p class="card__title">Requestor info</p>

					<div class="row">
						<div class="col-12 col-md-3 mb-4">
							<p class="label">Name</p>
							<p class="data">{{ data.requestor_name ?? '-' }}</p>
						</div>
						<div class="col-12 col-md-3 mb-4">
							<p class="label">Contact number</p>
							<p class="data">{{ data.phone ?? '-' }}</p>
						</div>
						<div class="col-12 col-md-3 mb-4">
							<p class="label">Email address</p>
							<p class="data">{{ data.email ?? '-' }}</p>
						</div>
						<div class="col-12 col-md-3 mb-4">
							<p class="label">Parent or student?</p>
							<p class="data">{{ capitalizeFirstLetter(data.requestor_type ?? '-') }}</p>
						</div>
						<div class="col-12 col-md-3 mb-4">
							<p class="label">Student name</p>
							<p class="data">{{ data.student_name ?? '-' }}</p>
						</div>
						<div class="col-12 col-md-3 mb-4">
							<p class="label">Student level / grade</p>
							<p class="data">{{ data.student_level ?? '-' }}</p>
						</div>
						<div class="col-12 col-md-3 mb-4">
							<p class="label">Type of lesson</p>
							<p class="data">{{ capitalizeFirstLetter(data.type_of_lesson ?? '-') }}</p>
						</div>
						<div class="col-12 col-md-3 mb-4">
							<p class="label">Address</p>
							<p class="data">{{ data.address ?? '-' }}</p>
						</div>
						<div class="col-12 col-md-3 mb-4">
							<p class="label">Unit</p>
							<p class="data">{{ data.unit ?? '-' }}</p>
						</div>
						<div class="col-12 col-md-3 mb-4">
							<p class="label">Postal code</p>
							<p class="data">{{ data.postal ?? '-' }}</p>
						</div>
						<div class="col-3">
							<p class="label">Status</p>
							<p class="data">{{ capitalizeFirstLetter(data.status ?? '-') }}</p>
						</div>						
					</div>
				</div>
			</div>
			
			<div class="card">
				<div class="card-body">
					<div v-if="data.service_name">
						<p class="card__title">{{ formatString(data.service_name ?? '-') }}: {{ data.subject_name }}</p>

						<div class="row">
							<div class="col-12 col-md-3 mb-4">
								<p v-if="data.service_id == 1 || data.service_id == 2" class="label">Duration per subject</p>
								<p v-else-if="data.service_id == 3 || data.service_id == 4" class="label">Duration per type of service</p>
								<p v-else-if="data.service_id == 5" class="label">Duration per type of enrichment</p>
								<p v-else class="label">Duration</p>
								<p class="data">{{ data.subject_duration_name + " Minutes" ?? '-' }}</p>
							</div>
							<div class="col-12 col-md-3 mb-4">
								<p v-if="data.service_id == 1 || data.service_id == 2" class="label">Frequency per subject</p>
								<p v-else-if="data.service_id == 3 || data.service_id == 4" class="label">Frequency per type of service</p>
								<p v-else-if="data.service_id == 5" class="label">Frequency per type of enrichment</p>
								<p v-else class="label">Frequency</p>
								<p class="data">{{ data.subject_frequency_name + " lesson/week" ?? '-' }}</p>
							</div>
							<div class="col-12 col-md-3 mb-4">
								<p v-if="data.service_id == 1 || data.service_id == 2" class="label">Tutor types (Includes rates)</p>
								<p v-else-if="data.service_id == 3 || data.service_id == 4" class="label">Service professionals (Includes rates)</p>
								<p v-else-if="data.service_id == 5" class="label">Enrichment specialists (Includes rates)</p>
								<p v-else class="label">Tutor Type</p>
								<p v-if="formattedTutorInfo && data.other_tutor_type" class="data">
									{{ formattedTutorInfo + ', ' + data.other_tutor_type + (data.preferred_budget == 1 ? ' ($' + data.tutor_rate + (data.service_id == 5 ? '/session)' : '/hr)') : '') }}
								</p>
								<p v-else-if="formattedTutorInfo" class="data">
									{{ formattedTutorInfo }}
								</p>
								<p v-else-if="data.other_tutor_type" class="data">
									{{ data.other_tutor_type  + (data.preferred_budget == 1 ? ' $(' + data.tutor_rate + (data.service_id == 5 ? '/session)' : '/hr)') : '') }}
								</p>
							</div>
							<div class="col-12 col-md-3 mb-4">
								<p class="label">Agreeable to the market rates?</p>
								<p v-if="data.preferred_budget == 1" class="data">No</p>
								<p v-else-if="data.preferred_budget == 0" class="data">Yes</p>
								<p v-else-if="data.preferred_budget == 2" class="data">To be quoted by Service Providers</p>
							</div>
							<div class="col-12 col-md-3 mb-4">
								<p class="label">Gender preference</p>
								<p class="data">{{ formatString(data.gender_preference) ?? '-' }}</p>
							</div>
							<div class="col-12 col-md-3 mb-4">
								<p class="label">All available days and timings</p>
								<p class="data">{{ data.preferred_time ?? '-' }}</p>
							</div>
							<div class="col-12 col-md-3 mb-4">
								<p class="label">Estimated commencement date</p>
								<p class="data">{{ data.estimated_commencement_date ?? '-' }}</p>
							</div>
							<div class="col-12">
								<p class="label">Remarks</p>
								<p class="data">{{ data.remarks ?? '-' }}</p>
							</div>
						</div>
					</div>
					<div v-else>
						No service selected
					</div>
				</div>
			</div>

            <div class="buttons">
				<div>
					<button type="button" :class="data.telegram_channel_id == 1 ? 'active' : ''" class="btn btn-outline-primary" @click="assignChannel(1, 'Tuition channel')">Tuition channel</button>
					<button type="button" :class="data.telegram_channel_id == 2 ? 'active' : ''" class="btn btn-outline-primary" @click="assignChannel(2, 'Therapy and Counselling channel')">Therapy and Counselling channel</button>
					<button type="button" :class="data.telegram_channel_id == 3 ? 'active' : ''" class="btn btn-outline-primary" @click="assignChannel(3, 'Enrichment channel')">Enrichment channel</button>
				</div>
				
				<div v-if="data.service_name && data.status == 'pending'">
					<button class="decline" @click="decline">Decline</button>
					<button class="approve" @click="approve">Approve request</button>
				</div>
            </div>
		</div>
	</Layout>
</template>

<style scoped>
.card-body {
	padding: 36px 48px;

	& .card__title {
		color: #505D69;
		font-family: 'Inter';
		font-weight: 700;
		font-size: 18px;
		line-height: 27px;
		border-left: 5px solid #38B6FF;
		padding-left: 8px;
		margin-bottom: 36px;
	}

	& .label {
		font-family: Nunito;
		font-weight: 500;
		font-size: 14px;
		line-height: 21px;
		color: #505D69;
		margin-bottom: 0;
	}
	
	& .data {
		font-family: Nunito;
		font-weight: 700;
		font-size: 16px;
		line-height: 24px;
		color: #505D69;
		margin-bottom: 0;
	}
}

.buttons {
    background-color: #ffffff;
    padding: 24px 20px;
    display: flex;
    justify-content: space-between;
	align-items: center;
    gap: 10px;

	div {
		display: flex;
		gap: 10px;
	}

    .decline {
        background-color: #F2F2F2;
        border: 1px solid #F2F2F2;
        border-radius: 6px;
        padding: 9px 20px;
        font-family: Nunito;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        color: #505D69;
    }

    .approve {
        background-color: #219653;
        border: 1px solid #219653;
        border-radius: 6px;
        padding: 9px 20px;
        font-family: Nunito;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        color: #ffffff;
    }
}

@media (max-width: 767px) {
	.buttons {
		flex-direction: column;

		div {
			flex-direction: column;
		}

		.decline {
			margin-top: 20px;
		}
	}
}
</style>